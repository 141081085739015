<template>
  <div v-if="!deployed">
    <Login />
  </div>
  <!--Font for whole app sucks-->
  <template v-if="!deployed && useSubdomain" >
    <div class="domain flex justify-center pt-10">
      <div class="rounded flex flex-nowrap md:text-7xl text-2xl domain-container font-bold">
        <input
         v-model="subdomain"
         @input="executeOnInput"
         autocomplete="off"
         maxlength="30"
         name="subdomain"
         placeholder="your-project"
         autofocus
         id="subdomain-input"
         class="outline-none text-right font-bold"/>

         <div class="safari-sux leading-normal">
           .spinup.site
         </div>

         <!--Deploy instantly, right now, for free. No signup, No credit card required sm text-->
          <!--if name is taken, suggest some?-->

        <template v-if="!available && !taken && !invalid">
          <svg version="1.1" style="display:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="white" stroke-width="5" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <polyline class="path check" fill="none" stroke="white" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
          </svg>
        </template>
        <template v-if="available && !invalid">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="5" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
          </svg>
        </template>

        <template v-if="taken || invalid">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
          </svg>
        </template>

      </div>
    </div>
    <div v-if="useSubdomain && !deployed" class="pt-1">
      <!--<button @click="useSubdomain = !useSubdomain" class="text-xs bg-white hover:bg-gray-100 text-gray-500 py-2 px-2 border-gray-100 rounded"><span class="underline">use your own domain </span></button>-->
      <button @click="useSubdomain = !useSubdomain" class="hover:bg-gray-100 text-gray-600 py-1 px-1 text-xs border border-gray-300 rounded shadow"><span class="">use your own domain </span></button>
      <!--TODO: buttons sideby side, 1) custom domain  2) auto-gen 3) spinup subdomain (show 2 not selected) k-->
    </div>

    <!--TODO -- START HERE-->
    <!--<div v-if="useSubdomain && !deployed" class="pl-5 pt-1">-->
      <!--<button @click="useSubdomain = !useSubdomain" class="text-xs bg-white hover:bg-gray-100 text-gray-500 py-2 px-2 border-gray-100 rounded">or, <span class="underline">auto-generate</span></button>-->
    <!--</div>-->
  </template>
    <div v-if="!useSubdomain && !deployed" class="">
    <div class="custom-domain-safari flex flex-col sm:m-auto pr-18 justify-center pt-1 ">
      <!--<div class="rounded flex flex-nowrap text-2xl font-bold">-->
      <input
           v-model="customDomain"
           @input="clearOtherDomainInput('customDomain')"
           autocomplete="off"
           maxlength="30"
           name="custom_domain"
           placeholder="your-domain.com"
           autofocus
           id="subdomain-input"
           class="outline-none text-center font-bold rounded flex flex-nowrap text-2xl font-bold md:text-7xl text-2xl"/>
        <div class="pt-1">
          <button @click="useSubdomain = !useSubdomain" class="hover:bg-gray-100 text-gray-600 py-1 px-1 text-xs border border-gray-300 rounded shadow"><span class="">use a spinup subdomain</span></button>
        </div>
        </div>
    </div>
  <template v-if="deployLink" >
  </template>
  <template v-if="deployed" >
    <section class="">
      <PostDeploy :deploy_link="deployLink" :cloud_link="cloud_link" :customDomain="customDomain" :deployed_at="deployed_at"/>
    </section>
  </template>
  <template v-else >


  <section class="mx-2 mb-12 mt-14 flex-grow justify-center" >

    <div class="">
    <div class="tabs">
      <button class="tab" :class="{ active: activeTab === 'static' }" @click="activeTab = 'static'">Static Project</button>
      <button class="tab" :class="{ active: activeTab === 'wasm' }" @click="activeTab = 'wasm'">Boxer</button>
    </div>
    <div class="sm:px-2" @mousemove="refocusInput">
      <div class="mt-4">
      <div v-show="activeTab === 'static'" class="">
        <FileUploader :deploy_type="activeTab" :subdomain="subdomain" :deployLink="deployLink" :customDomain="customDomain" @deployed="onDeploy($event)" @deployedLoggedIn="onLoggedInDeploy($event)"/>
      </div>
      <div v-show="activeTab === 'wasm'" class="">
        <!-- Wasm upload box -->
        <div class="wasm-upload-box">
          <!-- Wasm upload functionality goes here -->
        <FileUploader :deploy_type="activeTab" :subdomain="subdomain" :deployLink="deployLink" :customDomain="customDomain" @deployed="onDeploy($event)" @deployedLoggedIn="onLoggedInDeploy($event)"/>
        </div>
      </div>
      </div>
    </div>
    <template v-if="windoww.width > 640 && !deployLink">
      <div class="w-1/2 py-14 hidden">
        <Instructions />
      </div>
    </template>


    <!--<div class="sm:w-1/2 sm:px-2" @mousemove="refocusInput">-->
      <!--<FileUploader :subdomain="subdomain" :deployLink="deployLink" :customDomain="customDomain" @deployed="onDeploy($event)" @deployedLoggedIn="onLoggedInDeploy($event)"/>-->
    <!--</div>-->
    <!--<template v-if="windoww.width > 640 && !deployLink">-->
      <!--<div class="w-1/2 py-14 hidden">-->
        <!--<Instructions />-->
      <!--</div>-->
    <!--</template>-->

    </div>
  </section>
  </template>

  <!--<CurlRequest :deployLink="deployLink" :subdomain="subdomain" :customDomain="customDomain" />-->
  <Footer class="absolute bottom-0 pr-4"/>
</template>

<script>
// eslint-disable-next-line no-unused-vars

import FileUploader from '../components/FileUploader.vue'
import CurlRequest from '../components/CurlRequest.vue'
import Instructions from '../components/Instructions.vue'
import Login from '../components/auth/Login.vue'
import Footer from '../components/Footer.vue'
import requests from '@/requests/requests.js'
import PostDeploy from '@/components/PostDeploy.vue'

export default {
  name: 'App',
  components: {
    FileUploader,
   // CurlRequest,
    Instructions,
    PostDeploy,
    Footer,
    Login
  },
  data: function() {
    return {
      deployLink: '',
      cloud_link: '',
      deployed_at: '',
      deployed: false,
      activeTab: 'static',
      deploy_type: 'static',
      expration: '',
      subdomain: '',
      customDomain: '',
      available: null,
      useSubdomain: true,
      taken: null,
      invalid: null,
      windoww: {
          width: 0,
          height: 0
      },
    };
  },
  computed: {
    expiration_time(){
     return '3 days'
    }
  },
  created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
  },
  unmounted() {
      window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    refocusInput() {
      let input = document.getElementById("subdomain-input")
      if (document.activeElement !== input && input.value === '') { input.focus({preventScroll: true}) }
    },
    checkAvailable() {
      var format = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
      if (format.test(this.subdomain)) {
        this.invalid = true
        return
      }

      if (this.subdomain < 1) {
        this.taken = null
        this.available = null
        this.invalid = false
        return
      }

      requests.checkSubdomainAvailability(this.subdomain).then(response => response.json())
        .then((body) => {
        this.available = body.available
        if (this.available) {
          this.taken = false
          this.invalid = false
        } else {
          this.taken = true
          this.available = null
          this.invalid = false
          return
        }
      }).catch(function() {
        console.log('le probleme')
      });
    },
    handleResize() {
      this.windoww.width = window.innerWidth;
      this.windoww.height = window.innerHeight;
    },
    clearOtherDomainInput(current=''){
      if (current == 'customDomain') { this.subdomain = ''}
      if (current == 'subdomain') { this.customDomain = ''}
    },
    executeOnInput(){
      this.checkAvailable()
      this.clearOtherDomainInput('subdomain')
    },
    onDeploy(event){
      this.deployLink = event.deploy_link
      this.cloud_link = event.cloud_link
      this.deployed_at = event.deployed_at
      this.deployed = true
    },
    onLoggedInDeploy(event){
      //router.push({ name: 'Cloud', params: { deploy_link: event.target.value }})
      this.$router.push({ name: 'Cloud', params: { deploy_link: event.deploy_link }})
      //console.log(this.$router)

    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  margin-bottom: 40px;
  height: 88vh;
}

svg {
  width: 18px;
  display: block;
  margin: 5px;
}

.spinup-link {
    position: fixed;
    left: 1;
    top: 0;
}

.beta {
    position: fixed;
    left: 0;
    top: 20px;
}

@keyframes yellowfade {
    from { background: green; }
    to { background: transparent; }
}

.new-item {
    animation-name: yellowfade;
    animation-duration: 3s;
}

@media only screen and (min-width: 768px) {
  .domain-container {
    padding-right: 23rem;
  }
}

@media only screen and (max-width: 767px) {
  .domain-container {
    padding-right: 6rem;
  }
}

input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
}


/* for safari keychain icon (messes up render) */
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
   visibility: hidden;
   display: none !important;
   pointer-events: none;
   height: 0;
   width: 0;
   margin: 0;
}


@media not all and (min-resolution:.001dpcm) {
     @supports (-webkit-appearance:none) {
      .custom-domain-safari {
        padding-top: 65px;
      }

      .domain {
        padding-top: 65px;
        height: 90px;
      }

      .safari-sux {
        padding-top: 1px;
        line-height: 125%;
      }

      /* This is for placeholder being verrtically centered the same as input, infuriating */
      input {
        line-height: 100%;
        height: 1.3em;
      }

      /* for safari keychain icon (messes up render) */
      input::-webkit-contacts-auto-fill-button,
      input::-webkit-credentials-auto-fill-button {
         visibility: hidden;
         display: none !important;
         pointer-events: none;
         height: 0;
         width: 0;
         margin: 0;
      }

     }
}

.tabs {
  display: flex;
  justify-content: center;
}

.tab {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.tab.active {
  background-color: #ccc;
}

/* Content Box Styles */
.border {
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
}

.wasm-upload-box {
  /* Add styling for the wasm upload box */
}
</style>
